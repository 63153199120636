//string utilities
// we do a bunch of dynamic loading where we read in directories
// and match to strings pulled from Rainbird
// it aint pretty
// TODO - do this in the back with a dictionary, please!
export const getFileName = (string) =>
	string.replace("./", "").replace(".md", "");

export const kebabCase = (string) =>
	string ? string.trimStart().trimEnd().replace(" ", "-") : false;

export const unKebabCase = (string) =>
	string ? string.trimStart().trimEnd().replace("-", " ") : false;

export const getCondition = (string) =>
	string ? string.split("based on")[1] : false;

export const getDecision = (string) =>
	string ? string.split("is ")[1] : false;

export const getRelationship = (string) =>
	string ? string.split("has ")[1].replace("an ", "").replace("a ", "") : false;
