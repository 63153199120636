import { Route, Redirect } from "react-router-dom";
import { loggedIn, getProfile } from "../utils/auth";

const PublicLayout = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!loggedIn() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={
						getProfile() && getProfile().role === "admin"
							? "/admin/home"
							: "/candidate/questions"
					}
				/>
			)
		}
	/>
);

export default PublicLayout;
