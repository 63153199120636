import { React } from "react";
import "./Detail.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { kebabCase } from "../../../utils/strings";

const Detail = ({ fileKey, contextClass, id }) => {
	if (!fileKey) {
		return null;
	}
	const condition = require(`../../../Content/${kebabCase(fileKey)}.md`);

	return (
		<details className={`c-detail ${contextClass}`} id={id}>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={{
					// remap headings
					h1: ({ node, ...props }) => (
						<summary>
							{/* eslint-disable-next-line jsx-a11y/heading-has-content */}
							<h3 className="c-detail__title o-type__heading--4" {...props} />
						</summary>
					),
					h2: "h4",
					h3: "h5",
					h4: "h6",
				}}
			>
				{condition.default}
			</ReactMarkdown>
		</details>
	);
};

export default Detail;
