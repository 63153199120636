import { React } from "react";
import "./Reason.scss";
import { getCondition, getDecision } from "../../../../utils/strings";
import Markdown from "../../../Help/Markdown/Markdown";

const Reason = ({ condition, showImpact }) => {
	let decision = getDecision(condition.relationship);
	let reason = getCondition(condition.relationship);

	return (
		<details className="c-reason__details" value={condition.relationship}>
			<summary className="c-reason__summary">
				<span className="c-reason__relationship" data-bool={condition.object}>
					{`${reason}`}
					<span className="c-reason__icon">{condition.object ? "✓" : "✗"}</span>
				</span>
			</summary>

			<p className="c-reason__predicate">
				{" "}
				You {condition.object ? "are" : "are not"} {decision}.{" "}
				{showImpact && (
					<span>
						{"The impact of this fact on your suitability for the course is: "}
						{`${Math.round(condition.impact * 100) / 100}%`}
					</span>
				)}
			</p>

			{reason && <Markdown fileKey={reason} contextClass="c-reason__help" />}
		</details>
	);
};

export default Reason;
