import React from "react";
import Menu from "../Menu/Menu";

import "./Header.scss";

const Header = ({ className }) => {
	return (
		<header className={`c-header ${className}`}>
			<h1 className="c-header__heading o-type__heading--4">
				Eligibility and Suitability at{" "}
				<abbr className="c-header__brand " title="Code Your Future">
					CYF
				</abbr>
			</h1>
			<Menu className="c-header__menu" />
		</header>
	);
};

export default Header;
