import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Logo from "../../svgs/Logo.svg";

const Footer = ({ className }) => (
	<footer className={`c-footer ${className}`}>
		<h3 className="c-footer__help">
			<Link to="/help" className="c-footer__help o-button o-button--small ">
				Help
			</Link>
		</h3>
		<h4 className="c-footer__cite ">
			A project of{" "}
			<a href="//codeyourfuture.io">
				<span className="invisible">Code Your Future</span>
				<Logo />
			</a>
		</h4>
	</footer>
);

export default Footer;
