// export array of markdown files in the content folder
import { getFileName } from "../utils/strings";
const dir = require.context("./", true, /\.\/.*\.md$/);
let MarkdownIndex = {};

dir.keys().forEach((fileName) => {
	MarkdownIndex[getFileName(fileName)] = dir(fileName);
});
MarkdownIndex = Object.keys(MarkdownIndex);

export default MarkdownIndex;
