import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Menu.scss";
import { logout, loggedIn, getProfile } from "../../utils/auth";
import MenuIcon from "../../svgs/menu.svg";
import CloseIcon from "../../svgs/cross.svg";

export function Menu({ className }) {
	const isAdmin = getProfile() && getProfile().role === "admin";
	const isCandidate = getProfile() && getProfile().role === "candidate";
	const isLoggedIn = loggedIn();
	const [isOpen, setIsOpen] = useState(false);
	const userId = (getProfile() && getProfile().userId) || "";
	const history = useHistory();

	let stateClass = "c-menu--closed";

	const toggleMenu = () => (isOpen ? setIsOpen(false) : setIsOpen(true));

	const handleLogout = () => {
		logout();
		if (isAdmin) {
			history.push("/admin");
		} else {
			history.push("/");
		}
	};

	if (isOpen) {
		stateClass = "c-menu--open";
	}

	return (
		<section className={`${className} ${stateClass}`}>
			<header className="c-menu__header">
				<button
					className="c-menu__toggle o-button o-button--icon"
					onClick={toggleMenu}
				>
					<span className="invisible">Toggle Menu</span>

					<div className="c-menu__open">
						<MenuIcon />
					</div>
					<div className="c-menu__close">
						<CloseIcon />
					</div>
				</button>
			</header>
			<nav className={`c-menu__body ${stateClass}`}>
				<h2 className="c-menu__heading o-type__heading--4">Menu</h2>
				<ul className="c-menu__list">
					{!isLoggedIn && (
						<li className="c-menu__item">
							<Link
								className="c-menu__button
				 o-button o-button--secondary o-button--small"
								to="/"
							>
								Sign up
							</Link>
						</li>
					)}
					{(!isLoggedIn || isAdmin) && (
						<li className="c-menu__item c-menu__item--admin">
							<Link className="c-menu__link" to="/admin">
								Admin
							</Link>
						</li>
					)}
					{isAdmin && (
						<li className="c-menu__item c-menu__item--admin">
							<Link className="c-menu__link" to="/admin/reset-password">
								Change Admin Password
							</Link>
						</li>
					)}
					{isCandidate && (
						<li className="c-menu__item">
							<Link className="c-menu__link" to="/candidate/questions">
								Questions
							</Link>
						</li>
					)}
					{isCandidate && (
						<li className="c-menu__item">
							<Link
								className="c-menu__link"
								to={`/candidate/decision/${userId}`}
							>
								Decision
							</Link>
						</li>
					)}
					<li className="c-menu__item">
						<Link className="c-menu__link" to="/help">
							Help
						</Link>
					</li>
					{isLoggedIn && (
						<li className="c-menu__item">
							<button
								className="c-menu__button o-button o-button--small"
								onClick={handleLogout}
							>
								Log Out
							</button>
						</li>
					)}
				</ul>
			</nav>
		</section>
	);
}

export default Menu;
