import { React } from "react";
import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { kebabCase } from "../../../utils/strings";
import InfoIcon from "../../../svgs/info-circle.svg";
// this is essentially the same as the Detail component but just a plain section
// with no messing around with injecting summary tags into the markup either

const Markdown = ({ fileKey, contextClass, id }) => {
	if (!fileKey) {
		return null;
	}
	const file = require(`../../../Content/${kebabCase(fileKey)}.md`);

	return (
		<section className={`c-markdown ${contextClass}`} id={id}>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={{
					// remap headings
					h1: ({ node, ...props }) => (
						<h3 className={"c-markdown__heading o-type__heading--3"}>
							<InfoIcon className="c-markdown__icon" />
							<span {...props} />
						</h3>
					),
					h2: "h4",
					h3: "h5",
					h4: "h6",
				}}
			>
				{file.default}
			</ReactMarkdown>
		</section>
	);
};

export default Markdown;
