import "./LoginForm.scss";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import { setToken } from "../../../utils/auth";
import InfoIcon from "../../../svgs/info-circle.svg";

const LoginForm = () => {
	const history = useHistory();
	let [email, setEmail] = useState("");
	let [password, setPassword] = useState("");
	let loginDetails = {};
	loginDetails.email = email;
	loginDetails.password = password;

	const [errorMessage, setErrorMessage] = useState(false);
	const [submitMessage, setSubmitMessage] = useState(false);

	const validateEmail = (e) => {
		let email = e;
		if (validator.isEmail(email) && password !== "") {
			setErrorMessage(""); // if the email is valid and password is
			// not empty then we delete the error message.
		} else {
			setErrorMessage("Please enter a valid email/password");
		}
	};

	function handleEmail(e) {
		setEmail(e.target.value);
	}

	function handlePassword(e) {
		setPassword(e.target.value);
	}

	function fetchSessionToken() {
		axios
			.post("/api/auth/login/admin", loginDetails)
			.then((response) => {
				setToken(response.data.token);
				history.push("/admin/home");
			})
			.catch((error) => setErrorMessage(error.response.data));
	}

	function handleSubmit(e) {
		e.preventDefault();
		setErrorMessage("");
		setSubmitMessage("");
		validateEmail(email);
		fetchSessionToken();
	}

	return (
		<div className="c-login">
			<h2 className="c-login__heading o-type__heading--3 invisible">Log in</h2>
			<form className="c-form c-login__form" onSubmit={handleSubmit}>
				<fieldset className="c-login__fieldset c-form__fieldset">
					<legend className="c-login__legend c-form__legend">
						<h2 className="c-login__heading o-type__heading--1">
							<span className="c-login__brand">CYF</span> Admin Log in
						</h2>
					</legend>
					<div className="c-form__field c-login__field">
						<label htmlFor="email" className="c-form__label c-login__label">
							Email
						</label>
						<input
							type="email"
							name="email"
							value={email}
							onChange={handleEmail}
							className="c-login__email c-form__input"
							placeholder="Enter email"
						/>
					</div>

					<div className="c-form__field c-login__field">
						<label htmlFor="password" className="c-form__label c-login__label">
							Password
						</label>
						<input
							type="password"
							name="password"
							value={password}
							onChange={handlePassword}
							className="c-login__password c-form__input"
							placeholder="Enter password"
						/>
					</div>
				</fieldset>
				<Link
					to="/admin/forgot-password"
					className="c-login__action c-form__forgot o-button o-button--secondary"
				>
					Forgot password?
				</Link>
				<button
					type="submit"
					className="c-login__action c-form__submit o-button o-button--submit"
				>
					Login
				</button>
			</form>
			<div aria-live="assertive">
				{submitMessage && (
					<p
						className="signup__message
		  c-form__message c-form__message--success"
					>
						<InfoIcon /> {submitMessage}
					</p>
				)}

				{errorMessage && (
					<p
						aria-live="assertive"
						className="c-signup__error-message
			  c-form__message c-form__message--error"
					>
						<InfoIcon /> {errorMessage}
					</p>
				)}
			</div>
		</div>
	);
};

export default LoginForm;
