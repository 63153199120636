import "./Decision.scss";

import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getHeaders, getProfile, getDataRights } from "../../../utils/auth";
import { Link, Redirect } from "react-router-dom";
import Summary from "./Summary/Summary";
import ReasonList from "./ReasonList/ReasonList";

export const Decision = () => {
	const [candidateObject, setCandidateObject] = useState([]);
	const { candidateId } = useParams();
	const { certainty, object } = candidateObject;
	//certainty is actually the suitability confidence ranking
	const isCandidate = getProfile().role === "candidate";
	const isClearedToAccess = getDataRights(candidateId);
	// TODO should these not come from the candidate object?
	const isEligible = certainty > 0;
	const isTestCompleted = object && object.length > 0;

	useEffect(() => {
		const getCandidateInfo = async () => {
			const response = await axios.get(`/api/data/evidence/${candidateId}`, {
				headers: getHeaders(),
			});

			setCandidateObject(response.data);
		};
		getCandidateInfo();
		return () => {
			setCandidateObject({});
		};
	}, [candidateId]);

	if (!isClearedToAccess) {
		return (
			<>
				<h1>This isn&apos;t your page</h1>
				<Redirect
					to={`/candidate/decision/${getProfile().userId.toString()}`}
				/>
			</>
		);
	}

	///else start the decision page

	return (
		<article className="c-decision">
			<Summary
				candidateObject={candidateObject}
				isCandidate={isCandidate}
				isTestCompleted={isTestCompleted}
				isEligible={isEligible}
			/>
			{isTestCompleted && (
				<ReasonList candidateObject={candidateObject} isEligible={isEligible} />
			)}
			{isCandidate && isTestCompleted && (
				<footer className="c-decision__footer o-type__heading--2">
					<Link
						to="/candidate/questions"
						className="o-button o-button--secondary"
					>
						Retake
					</Link>
				</footer>
			)}
		</article>
	);
};

export default Decision;
