import "./ForgotPassword.scss";
import React, { useState } from "react";
import validator from "validator";
import axios from "axios";
import InfoIcon from "../../../svgs/info-circle.svg";

const ForgotPassword = () => {
	let [email, setEmail] = useState("");

	const [errorMessage, setErrorMessage] = useState("");
	const [submitMessage, setSubmitMessage] = useState("");

	const validateEmail = (e) => {
		let email = e;
		if (validator.isEmail(email)) {
			setErrorMessage(""); // if the email is valid we delete the error message.
		} else {
			setErrorMessage("Please enter your email.");
		}
	};

	function handleEmail(e) {
		setEmail(e.target.value);
	}

	function handleSubmit(e) {
		e.preventDefault();
		setErrorMessage("");
		setSubmitMessage("");
		validateEmail(email);
		axios
			.put("/api/auth/forgot-password", { email })
			.then((response) => {
				setSubmitMessage(response.data);
			})
			.catch((error) => setErrorMessage(error.response.data));
	}

	return (
		<div className="c-forgot">
			<h2 className="c-forgot__heading o-type__heading--1">
				Forgotten your password?
			</h2>
			<p className="c-forgot__summary">
				{"Put your email in the form and you'll get a reset link, quick sharp."}
			</p>
			<form className="c-form c-forgot__form" onSubmit={handleSubmit}>
				<div className="c-form__field c-forgot__field">
					<label htmlFor="email" className="c-form__label c-forgot__label">
						Email
					</label>
					<input
						type="email"
						name="email"
						value={email}
						onChange={handleEmail}
						className="c-forgot__email c-form__input"
						placeholder="Enter your email"
					/>
				</div>
				<button
					className="c-forgot__action c-form__forgot o-button o-button--submit"
				>
					Send me a reset link
				</button>
			</form>
			<div aria-live="assertive">
				{submitMessage && (
					<p
						className="c-forgot__message
		  c-form__message c-form__message--success"
					>
						<InfoIcon /> {submitMessage}
					</p>
				)}

				{errorMessage && (
					<p
						aria-live="assertive"
						className="c-forgot__error-message
			  c-form__message c-form__message--error"
					>
						<InfoIcon /> {errorMessage}
					</p>
				)}
			</div>
		</div>
	);
};

export default ForgotPassword;
