import { useState } from "react";
import validator from "validator";
import axios from "axios";
import "./Signup.scss";
import EmailIcon from "../../../svgs/email.svg";
import InfoIcon from "../../../svgs/info-circle.svg";

export function Signup() {
	const [candidateLoginMessage, setCandidateLoginMessage] = useState("");

	let [user, setUser] = useState({
		givenName: "",
		familyName: "",
		email: "",
	});

	const handleChange = (event) => {
		setUser({ ...user, [event.target.name]: event.target.value });
	};

	let [errorMessage, setErrorMessage] = useState(false);

	const validateEmail = (e) => {
		let email = e;
		if (validator.isEmail(email)) {
			setErrorMessage("");
			return true; // if email is acceptable then we proceed to send api
		} else {
			setErrorMessage("That email doesn't look right. Please try again.");
			return false;
		}
	};

	function handleSubmit(e) {
		e.preventDefault();
		let acceptableEmail = validateEmail(user.email);
		if (acceptableEmail) {
			setUser({ givenName: "", familyName: "", email: "" });
			sendDataToApi();
		}
	}

	function sendDataToApi() {
		axios
			.post("/api/auth/login/candidate", user)
			.then((response) => {
				setCandidateLoginMessage(response.data.message);
			})
			.catch((error) => {
				setErrorMessage(error.response.data);
			});
	}

	return (
		<section className="c-signup">
			<h3 className="c-signup__heading invisible">
				Sign up to get a magic link
			</h3>
			<form className="c-signup__form c-form" onSubmit={handleSubmit}>
				<div className="c-signup__field c-form__field">
					<label className="c-form__label c-signup__label" htmlFor="givenName">
						Given Name
					</label>
					<input
						type="text"
						name="givenName"
						id="givenName"
						value={user.givenName}
						onChange={handleChange}
						autoComplete={"given-name"}
						spellCheck={"false"}
						required
					/>
				</div>
				<div className="c-signup__form-group">
					<label className="c-form__label c-signup__label" htmlFor="familyName">
						Family Name
					</label>
					<input
						type="text"
						name="familyName"
						id="familyName"
						value={user.familyName}
						onChange={handleChange}
						autoComplete={"family-name"}
						spellCheck={"false"}
						className="c-signup__input c-form__input"
						required
					/>
				</div>

				<div className="c-signup__field c-signup__email c-form__field">
					<label className="c-form__label c-signup__label" htmlFor="email">
						Email
					</label>
					<input
						type="email"
						name="email"
						id="email"
						value={user.email}
						onChange={handleChange}
						autoComplete={"email"}
						spellCheck={"false"}
						required
						className="c-signup__input c-form__input"
					/>
					{errorMessage && (
						<p
							aria-live="assertive"
							className="c-signup__error-message
			  c-form__message c-form__message--error"
						>
							<InfoIcon /> {errorMessage}
						</p>
					)}
				</div>
				<button className="c-signup__action o-button " type="submit">
					Sign Up
				</button>
			</form>
			<div aria-live="assertive">
				{candidateLoginMessage && (
					<p
						className="signup__message
		  c-form__message c-form__message--success"
					>
						<EmailIcon /> {candidateLoginMessage}
					</p>
				)}
			</div>
		</section>
	);
}

export default Signup;
