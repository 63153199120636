import React from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getProfile, setToken } from "../../utils/auth";

function RedirectPage() {
	const location = useLocation();
	const token = new URLSearchParams(location.search).get("token") || false;
	setToken(token);
	const { role } = getProfile();

	return (
		<>
			<h1 className="c-redirect__heading o-type__heading--1">Redirecting...</h1>
			{role === "candidate" && <Redirect to="/candidate/questions" />}
			{role === "admin" && <Redirect to="/admin/reset-password" />}
			{!token && <Redirect to="/not-found" />}
		</>
	);
}

export default RedirectPage;
