import { React } from "react";
import "./Question.scss";
import InfoIcon from "../../../../svgs/info-circle.svg";
import DropDown from "../DropDown/DropDown";
export function Question({
	setNameOfUser,
	firstQuestion,
	submitAnswer,
	currentQuestion,
	setCurrentAnswer,
	currentAnswer,
	isDropDown,
	mapQuestionObject,
	setIsDropDown,
	isBoolean,
	setIsBoolean,
	errorMessage,
}) {
	function handleChange(e) {
		setCurrentAnswer(e.target.value);
		if (firstQuestion) {
			setNameOfUser(e.target.value);
		}
	}

	function onPressSubmit(e) {
		e.preventDefault();
		submitAnswer();
		setCurrentAnswer("");
	}

	return (
		<>
			<form className="c-question__form c-form" onSubmit={onPressSubmit}>
				<label htmlFor="answer" className="c-form__label o-type__heading--2">
					{currentQuestion}
				</label>
				{isDropDown ? (
					<DropDown
						setCurrentAnswer={setCurrentAnswer}
						mapQuestionObject={mapQuestionObject}
						submitAnswer={submitAnswer}
						setIsDropDown={setIsDropDown}
						isBoolean={isBoolean}
						setIsBoolean={setIsBoolean}
					/>
				) : (
					<div
						className="c-question__group
		   c-form__group--inline c-form__group--inline-balance"
					>
						<input
							className="c-question__input c-form__input"
							type="text"
							value={currentAnswer}
							onChange={handleChange}
							id="answer"
						/>
						<button className="c-question__action o-button o-button--submit">
							Next &rarr;
						</button>
					</div>
				)}
				{errorMessage && (
					<p aria-live="assertive"
						className="c-question__error-message c-form__message c-form__message--error">
						<InfoIcon /> {errorMessage}</p>
				)}
			</form>
		</>
	);
}

export default Question;
