import "./StartButton.scss";

import React from "react";
import axios from "axios";
import { getHeaders } from "../../../../utils/auth";

export function StartButton({
	setSessionId,
	setStartQuestions,
	setCurrentQuestion,
	createMapQuestionObject,
	setIsBoolean,
	setIsDropDown,
}) {
	// This fetch will import the start session ID
	// IN this get I will have to set JWT token in header.
	// This end point will change ,
	// and it will send session ID and first question,
	// I will also hve to delete sendName function.
	function handleStartPress() {
		let api = "/api/data/start-session";
		const config = { headers: getHeaders() };

		const bodyParameters = {
			key: "value",
		};
		axios
			.get(api, config, bodyParameters)
			.then((response) => response.data)
			.then((response) => {
				setSessionId(response.sessionID);
				setCurrentQuestion(response.question.prompt);
				createMapQuestionObject(response.question);
				if (response.question.dataType === "boolean") {
					setIsBoolean(true);
					setIsDropDown(true);
				}
				if (response.question.options) {
					setIsDropDown(true);
				}
				setStartQuestions(true);
			})
			.catch((error) => console.log(error));
	}
	return (
		<>
			<button className="o-button c-start" onClick={handleStartPress}>
				Start
			</button>
		</>
	);
}

export default StartButton;
