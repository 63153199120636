// Define a default UI for filtering
export default function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;

	return (
		<input
			className={"c-form__input c-column-filter__input"}
			value={filterValue || ""}
			onChange={(e) => {
				// Set undefined to remove the filter entirely
				setFilter(e.target.value || undefined);
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}
