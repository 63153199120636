import { useState } from "react";
import { useAsyncDebounce } from "react-table";

export default function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="c-table-actions__global-filter c-global-filter">
			<label
				className="c-global-filter__search c-form__search"
				htmlFor="filter"
			>
				<span className="invisible">Search and filter records</span>
				<input
					type="search"
					value={value || ""}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={`${count} records...`}
					id="filter"
					className="c-form__input c-global-filter__input"
				/>
			</label>
			<div className="invisible" aria-live="polite">
				${count} records found
			</div>{" "}
		</div>
	);
}
