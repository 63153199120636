import React from "react";
import "./AdminHome.scss";
import { loggedIn, getProfile } from "../../../utils/auth";
import LoginForm from "../LoginForm/LoginForm";
import CandidateTable from "../CandidateTable/CandidateTable";

const AdminHome = () => {
	const isAdmin = getProfile() && getProfile().role === "admin";
	const isLoggedIn = loggedIn();

	return (
		<>
			{isAdmin && (
				<section className="c-admin-home">
					<h2 className="c-admin-home__heading o-type__heading--1">Admin</h2>
					<CandidateTable />
				</section>
			)}
			{!isLoggedIn && <LoginForm />}
		</>
	);
};

export default AdminHome;
