import React from "react";
import { Link } from "react-router-dom";
import "./Summary.scss";

const Summary = ({ candidateObject, isCandidate, isTestCompleted }) => {
	const { certainty } = candidateObject;
	//certainty is actually the suitability confidence ranking
	const color = (suitability) =>
		suitability === 0 ? "failure" : suitability < 50 ? "warning" : "success";

	function summarize(suitability) {
		if (suitability === 0) {
			return `You don't seem eligible 
	for CYF Fundamentals this time.`;
		}
		if (suitability > 50) {
			return `You are eligible 
	  for CYF Fundamentals.`;
		}
		return `You seem eligible, but we have some questions. 
	An admin will contact you to talk about this.`;
	}

	return (
		<header className="c-decision__header c-summary">
			{isTestCompleted && (
				<>
					<h2 className="c-summary__heading o-type__heading--1">
						Decision for {candidateObject.subject}
					</h2>
					<h3 className="c-summary__subheading o-type__heading--3">
						{summarize(certainty)}
					</h3>
					<p
						className={`o-type__heading--2 c-summary__percentage 
			c-summary__percentage--${color(certainty)}`}
					>
						{certainty}%
					</p>
				</>
			)}

			{!isTestCompleted && isCandidate && (
				<>
					<h2 className="c-summary__heading o-type__heading--1">
						Not complete
					</h2>
					<p className="c-summary__subheading o-type__heading--2">
						You need to answer your questions first{" "}
					</p>
					<p className="c-summary__redirect">
						We don&apos;t have a decision for you yet. Please{" "}
						<Link to="/candidate/questions">answer all the questions</Link> to
						get your decision.
					</p>
					<p
						className={`o-type__heading--2 c-summary__percentage 
			   c-summary__percentage--unknown`}
					>
						?
					</p>
				</>
			)}
		</header>
	);
};

export default Summary;
