import React from "react";
import Reason from "../Reason/Reason";
import "./ReasonList.scss";

const ReasonList = ({ candidateObject, isEligible }) => {
	return (
		<section className="c-decision__reason-list c-reason-list">
			<h3 className="c-reason-list__heading o-type__heading--2">Reasons</h3>
			<p className="c-reason-list__summary">
				Here are the reasons we made this decision. Press the triangles to see
				our answers to frequently asked questions about each topic.
			</p>
			<h4 className="c-reason-list__subheading o-type__heading--3">
				Eligibility
			</h4>
			<ul className="c-reason-list__list">
				{candidateObject.eligibilityConditions?.map((condition, index) => (
					<li className="c-reason-list__item" key={index}>
						<Reason
							condition={condition}
							candidateObject={candidateObject}
							showImpact={false}
						/>
					</li>
				))}
			</ul>
			{/* Only show the suitability reasoning if the candidate is eligible
			so we don't overwhelm or confuse people
			also let's skip the you are eligible for course reason,
			which is always first */}
			{isEligible && (
				<>
					<h4 className="c-reason-list__subheading o-type__heading--3">
						Suitability
					</h4>
					<ul className="c-reason-list__list">
						{candidateObject.suitabilityConditions?.map((condition, index) =>
							index > 0 ? (
								<li className="c-reason-list__item" key={index}>
									<Reason
										condition={condition}
										candidateObject={candidateObject}
										showImpact={true}
									/>
								</li>
							) : null
						)}
					</ul>
				</>
			)}
		</section>
	);
};

export default ReasonList;
