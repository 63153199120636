import { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getHeaders } from "../../../utils/auth";
import NumberRangeColumnFilter from "./NumberRangeColumnFilter";
import SelectColumnFilter from "./SelectColumnFilter";
import Table from "./Table";

function CandidateTable() {
	const [arrayCandidate, setArrayCandidate] = useState([]);

	useEffect(() => {
		const getCandidates = async () => {
			const response = await axios.get("/api/admins/candidates", {
				headers: getHeaders(),
			});

			// create the name field from givenName and familyName
			const data = response.data.map((candidate) => {
				return {
					...candidate,
					name: `${candidate.givenName} ${candidate.familyName}`,
				};
			});

			// update the candidate array
			setArrayCandidate(data);
		};
		getCandidates();
	}, []);

	/**
	 * - Columns is a simple array right now,
	 * but it will contain some logic later on.
	 * It is recommended by react-table to memoize the columns data
	 * react-table is also flexible enough to create grouped table headers
	 */
	const columns = useMemo(
		() => [
			{
				// id: "name",
				Header: "Name",
				// accessor is the "key" in the data
				accessor: "name",
				// Use our custom `fuzzyText` filter on this column
				filter: "fuzzyText",
				Cell: ({ cell: { value } }) => <strong>{value}</strong>,
			},
			{
				Header: "Cohort",
				accessor: "cohortCity",
				Filter: SelectColumnFilter,
				filter: "includes",
			},
			{
				Header: "Suitability %",
				accessor: "certainty",
				Filter: NumberRangeColumnFilter,
				filter: "between",
			},
			{
				Header: "Link",
				id: "link",
				accessor: "userId",
				disableFilters: true,
				disableSortBy: true,
				Cell: ({ cell: { value } }) => (
					<Link
						className="c-candidates__nav c-candidates__nav--link"
						to={`/candidate/decision/${value}`}
					>
						{"Examine reasons..."}
					</Link>
				),
			},
			{
				id: "isTestCompleted",
				Header: "Complete",
				/**
				 * this changes the values stored in the table.
				 * filters work with this method.
				 */
				accessor: (testStatus) => (testStatus.isTestCompleted ? "Yes" : "No"),
				/**
				 * this changes the data displayed in the table but
				 * the value attribute is not changed.
				 * With the following method filters don't work.
				 */
				// Cell: ({ cell: { value } }) => (value ? "Yes" : "No"),
				Filter: SelectColumnFilter,
				filter: "includes",
			},
			{
				Header: "Created",
				accessor: "date1", // accessor is the "key" in the data
				filter: "fuzzyText",
			},
			{
				Header: "Tries",
				accessor: "numOfTests", // accessor is the "key" in the data
				Filter: NumberRangeColumnFilter,
				filter: "between",
			},
			{
				Header: "Last Try",
				accessor: "date2", // accessor is the "key" in the data
				filter: "fuzzyText",
			},
		],
		[]
	);

	return (
		<div className="c-candidates-table">
			<Table columns={columns} data={arrayCandidate} />
		</div>
	);
}

export default CandidateTable;
