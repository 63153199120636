import "./ResetPassword.scss";
import React, { useState } from "react";
import axios from "axios";
import InfoIcon from "../../../svgs/info-circle.svg";
import { getToken, logout } from "../../../utils/auth";

const ResetPassword = () => {
	const token = getToken();

	let [newPassword, setNewPassword] = useState({
		password: "",
		confirmPassword: "",
	});

	const [errorMessage, setErrorMessage] = useState("");
	const [submitMessage, setSubmitMessage] = useState("");

	const isPasswordAcceptable = (password) => {
		const hasLength = (length) => {
			return (word) => word.length >= length;
		};
		const containsLowercaseLetter = (word) => /[a-z]/g.test(word);
		const containsUppercaseLetter = (word) => /[A-Z]/g.test(word);
		const containsNumber = (word) => /[0-9]/g.test(word);

		return [
			hasLength(16),
			containsLowercaseLetter,
			containsNumber,
			containsUppercaseLetter,
		].every((check) => check(password));
	};

	const validatePassword = ({ password, confirmPassword }) => {
		let error = "";
		if (password !== confirmPassword) {
			error = "Both the fields should contain same password.";
		} else if (!isPasswordAcceptable(password)) {
			error =
				"Password should include one lowercase letter, one uppercase letter, one numeric value and must be at least 16 characters long.";
		}
		return error;
	};

	const handleChange = (event) => {
		setNewPassword({ ...newPassword, [event.target.name]: event.target.value });
	};

	function submitPassword() {
		axios
			.put(`/api/admins/reset-password?token=${token}`, {
				password: newPassword.password,
			})
			.then((response) => {
				setSubmitMessage(response.data);
				setErrorMessage("");
			})
			.catch((error) => setErrorMessage(error.response.data));
	}

	function handleSubmit(e) {
		e.preventDefault();
		const error = validatePassword(newPassword);
		if (error) {
			setErrorMessage(error);
			return;
		}
		setErrorMessage("");
		setSubmitMessage("");
		submitPassword();
		setNewPassword({
			password: "",
			confirmPassword: "",
		});
		logout();
	}

	return (
		<div className="c-reset">
			<h2 className="c-reset__heading o-type__heading--1">Reset Password</h2>
			<p className="c-reset__info">
				Password should include at least one lowercase letter, one uppercase
				letter, one numeric value and must be longer than 16 characters.
			</p>
			<p className="c-reset__info">Try using a passphrase.</p>
			<form className="c-form c-reset__form" onSubmit={handleSubmit}>
				<fieldset className="c-reset__fieldset c-form__fieldset">
					<div className="c-form__field c-reset__field">
						<label htmlFor="password" className="c-form__label c-reset__label">
							Password
						</label>
						<input
							type="password"
							name="password"
							value={newPassword.password}
							onChange={handleChange}
							className="c-reset__password c-form__input"
							placeholder="Enter password"
						/>
					</div>

					<div className="c-form__field c-reset__field">
						<label htmlFor="password" className="c-form__label c-reset__label">
							Retype Password
						</label>
						<input
							type="password"
							name="confirmPassword"
							value={newPassword.confirmPassword}
							onChange={handleChange}
							className="c-reset__password c-form__input"
							placeholder="Confirm password"
						/>
					</div>
				</fieldset>
				<button
					type="submit"
					className="c-reset__action c-form__submit o-button o-button--submit"
				>
					Save password
				</button>
			</form>
			<div aria-live="assertive">
				{submitMessage && (
					<p
						className="c-reset__message
		  c-form__message c-form__message--success"
					>
						<InfoIcon /> {submitMessage}
					</p>
				)}

				{errorMessage && (
					<p
						aria-live="assertive"
						className="c-reset__error-message
			  c-form__message c-form__message--error"
					>
						<InfoIcon /> {errorMessage}
					</p>
				)}
			</div>
		</div>
	);
};

export default ResetPassword;
