import React from "react";

import Signup from "../Signup/Signup";
import "./Home.scss";

const Home = () => {
	return (
		<section className="c-home">
			<h2 className="o-type__heading--1 c-home__heading">
				<span className="c-home__brand">CYF</span> Rainbird
			</h2>
			<h3 className="c-home__subheading o-type__heading--2">
				Clarity, Dignity, Simplicity, and <strong>Fairness</strong>
			</h3>
			<Signup />
		</section>
	);
};

export default Home;
