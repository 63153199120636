import { React } from "react";
import "./Help.scss";
import Markdown from "../Help/Markdown/Markdown";
import Detail from "../Help/Detail/Detail";
import MarkdownIndex from "../../Content/MarkdownIndex";
import { unKebabCase } from "../../utils/strings";

// this page is public
// Expect CYF to link to it and probably also deep link into fragments
// Any markdown files added into the
//Content folder will be pulled into this view

const Help = () => {
	return (
		<article className={"c-help"}>
			<header className="c-help__header">
				<h2 className={"c-help__heading o-type__heading--1"}>Help</h2>
			</header>
			<nav className="c-help__nav">
				<h3 className="c-help__nav-heading o-type__heading--3">On this page</h3>
				<ul className="c-help__list">
					{MarkdownIndex.map((item, index) =>
						index > 0 ? (
							<li className="c-help__item" key={index}>
								<a className="c-help__link" href={`#${item}`}>
									{unKebabCase(item)}
								</a>
							</li>
						) : null
					)}
				</ul>
			</nav>
			<section className="c-help__details">
				{MarkdownIndex.map((fileName, index) => {
					//just print the overview
					if (index === 0) {
						return (
							<Markdown
								fileKey={fileName}
								key={index}
								contextClass="c-help__overview"
								id={fileName}
							/>
						);
					} else {
						return (
							<Detail
								fileKey={fileName}
								key={index}
								contextClass="c-help__detail"
								id={fileName}
							/>
						);
					}
				})}
			</section>
		</article>
	);
};

export default Help;
