import { Route, Switch } from "react-router-dom";
import PublicLayout from "./layout/Public";
import PrivateLayout from "./layout/Private";
import Home from "./Views/Candidate/Home/Home";
import Help from "./Views/Help/Help";
import RedirectPage from "./Views/Redirect/RedirectPage";
import Admin from "./Views/Admin/AdminHome/AdminHome";
import Decision from "./Views/Candidate/Decision/Decision";
import Questions from "./Views/Candidate/Questions/QuestionsPage/QuestionsPage";
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
import ResetPassword from "./Views/Admin/ResetPassword/ResetPassword";
import ForgotPassword from "./Views/Admin/ForgotPassword/ForgotPassword";

import "./App.scss";

const App = () => {
	return (
		<div className="c-app">
			<Header className="c-app__header" />

			<main className={"c-main c-app__main"}>
				<Switch>
					<PublicLayout exact path="/" component={Home} />
					<PublicLayout
						exact
						path="/candidate/redirect"
						component={RedirectPage}
					/>
					<PublicLayout exact path="/admin/redirect" component={RedirectPage} />
					<PublicLayout exact path="/admin" component={Admin} />
					<PublicLayout
						exact
						path="/admin/forgot-password"
						component={ForgotPassword}
					/>
					<PrivateLayout
						exact
						path="/admin/reset-password"
						component={ResetPassword}
						roles={["admin"]}
					/>
					<PrivateLayout
						exact
						path="/admin/home"
						component={Admin}
						roles={["admin"]}
					/>
					<PrivateLayout
						exact
						path="/candidate/questions"
						component={Questions}
						roles={["admin", "candidate"]}
					/>
					<PrivateLayout
						exact
						path="/candidate/decision/:candidateId"
						component={Decision}
						roles={["admin", "candidate"]}
					/>
					<Route exact path="/help" component={Help} />
					<Route
						exact
						path="/not-found"
						component={() => (
							<>
								<h1 className="o-text__heading--1">Not Found 404</h1>
							</>
						)}
					/>
				</Switch>
			</main>
			<Footer className="c-app__footer" />
		</div>
	);
};

export default App;
