import { Route, Redirect } from "react-router-dom";
import { isAuthorized } from "../utils/auth";

const PrivateLayout = ({
	component: Component,
	roles,
	containerWidth,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthorized(roles) ? <Component {...props} /> : <Redirect to="/" />
			}
		/>
	);
};

export default PrivateLayout;
