import decode from "jwt-decode";
import axios from "axios";
import "regenerator-runtime/runtime";
export const getHeaders = () => {
	const idToken = localStorage.getItem("id_token");
	if (idToken) {
		return {
			Authorization: `Bearer ${idToken}`,
		};
	}
	return null;
};

export const setDefaultAxiosHeaders = () => {
	const token = localStorage.getItem("id_token");
	if (token) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	}
};

export const getToken = () => localStorage.getItem("id_token");

export const getProfile = () => {
	const token = getToken();
	if (token) {
		setDefaultAxiosHeaders();
		return decode(token);
	}
	return null;
};

export const setToken = (token) => {
	localStorage.setItem("id_token", token);
	setDefaultAxiosHeaders();
};

export const logout = () => {
	localStorage.removeItem("id_token");
	delete axios.defaults.headers.common.Authorization;
};

export const isTokenExpired = (token) => {
	try {
		const decoded = decode(token);
		if (decoded && decoded.exp && decoded.exp < Date.now() / 1000) {
			logout();
			window.location.replace("/");
			return true;
		}
		return false;
	} catch (err) {
		return true;
	}
};

export const loggedIn = () => {
	const token = getToken();
	return !!token && !isTokenExpired(token);
};

export const isTokenAuthorized = (roles) => {
	try {
		const profile = getProfile();
		if (!roles && roles.length === 0) {
			return false;
		}
		if (profile.role === "admin") {
			return true;
		}
		if (roles.includes(profile.role)) {
			return true;
		}
		logout();
		window.location.replace("/");
		return false;
	} catch (err) {
		return false;
	}
};

export const isAuthorizedViewer = (candidateId) => {
	try {
		if (!candidateId) {
 			return false;
		}
		const profile = getProfile();
		if (profile.role === "admin") {
			return true;
		}
		if (profile.userId.toString() === candidateId.toString()) {
			return true;
		}
		return false;
	} catch (err) {
		return false;
	}
};

export const getDataRights = (candidateId) => isAuthorizedViewer(candidateId);
export const isAuthorized = (roles) => loggedIn() && isTokenAuthorized(roles);
