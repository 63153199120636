import { React, useState } from "react";
import Select from "react-select";
import "./DropDown.scss";

const booleanObject = [
	{ label: "yes", value: "yes" },
	{ label: "no", value: "no" },
];

function DropDown({
	setCurrentAnswer,
	mapQuestionObject,
	submitAnswer,
	setIsDropDown,
	isBoolean,
	setIsBoolean,
}) {
	const [answer, enableButton] = useState("");
	function handleChange(e) {
		setCurrentAnswer(e.value);
		enableButton(e.value);
	}

	function onPressSubmit() {
		submitAnswer();
		setCurrentAnswer("");
		setIsDropDown(false);
		setIsBoolean(false);
	}

	let optionsAsObjects = {};
	if (isBoolean) {
		optionsAsObjects = booleanObject;
	} else {
		optionsAsObjects = mapQuestionObject.options.map((option) => {
			return { label: option, value: option };
		});
	}
	return (
		<div
			className="c-dropdown
    c-form__group--inline c-form__group--inline-balance"
		>
			<Select
				className="c-dropdown__select"
				onChange={handleChange}
				options={optionsAsObjects}
				id="answer"
			/>
			<button
				className="c-dropdown__action o-button o-button--submit"
				onClick={onPressSubmit}
				disabled={!answer}
			>
				Next &rarr;
			</button>
		</div>
	);
}

export default DropDown;
