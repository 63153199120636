import "./QuestionsPage.scss";

import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";

import Question from "../Question/Question";
import StartButton from "../StartButton/StartButton";

import { getHeaders } from "../../../../utils/auth";
//on this page I should Take session id and name of the student.
// and then submit it to the backend
// and then start the questions component
// WIll have to use post request to send data.

export function QuestionsPage() {
	const history = useHistory();

	const [startQuestions, setStartQuestions] = useState(false);
	// When start button is pressed then we are taken to the questions.

	const [sessionId, setSessionId] = useState("");
	// this is the session id that I will receive
	// from the api and then I need to send this session id with name to backend.

	const [currentQuestion, setCurrentQuestion] = useState(
		"Welcome. Press start to begin",
	);
	// This will be the first question so that we can extract name of the user.

	const [currentAnswer, setCurrentAnswer] = useState("");
	// This variable will store the answers of the user.

	const [isDropDown, setIsDropDown] = useState(false);

	const [isBoolean, setIsBoolean] = useState(false);

	//we do some rudimentary validation because they put in a postcode string
	const [errorMessage, setErrorMessage] = useState("");

	const [mapQuestionObject, setMapQuestionObject] = useState({
		userId: "",
		type: "",
		subject: "",
		relationship: "",
		prompt: "",
		dataType: "",
		object: "",
	}); // This is the structure of the object that we need to send to the api.

	function submitAnswer() {
		createMapQuestionObject(mapQuestionObject);
		submitMapQuestions();
	}

	// in this function we are creating the object that will
	// be sent back to api to receive the next question.
	// In this one we should check the data type
	// and then write the "object" field.
	function createMapQuestionObject(obj) {
		if (obj.dataType === "number") {
			setCurrentAnswer(parseInt(currentAnswer));
		}
		const temporarySendingObject = obj;
		temporarySendingObject.object = currentAnswer;
		setMapQuestionObject(temporarySendingObject);
		setCurrentAnswer("");
		setErrorMessage("");
	}

	function submitMapQuestions() {
		// eslint-disable-next-line max-len
		let api = `/api/data/response/${sessionId}`;
		const config = { headers: getHeaders() };

		const bodyParameters = {
			key: "value",
		};
		axios
			.post(api, mapQuestionObject, config, bodyParameters)
			.then((response) => {
				if (response.data.type === "RESPONSE_TYPE_RESULT") {
					let userId = response.data.userId;
					history.push(`/candidate/decision/${userId}`);
				}
				createMapQuestionObject(response.data);
				setCurrentQuestion(response.data.prompt);

				if (response.data.dataType === "boolean") {
					setIsBoolean(true);
					setIsDropDown(true);
				}
				if (response.data.options) {
					setIsDropDown(true);
				}
			})
			.catch((error) => {
				console.log(error);
				setErrorMessage("Sorry, something went wrong. Please try again.");
			});
	}

	return (
		<section className="c-questions">
			<h2 className="c-questions__heading o-type__heading--1">
				CYF Eligibility
			</h2>
			<div className="c-questions__form">
				{startQuestions ? (
					<Question
						submitAnswer={submitAnswer}
						currentQuestion={currentQuestion}
						setCurrentAnswer={setCurrentAnswer}
						currentAnswer={currentAnswer}
						isDropDown={isDropDown}
						mapQuestionObject={mapQuestionObject}
						setIsDropDown={setIsDropDown}
						isBoolean={isBoolean}
						setIsBoolean={setIsBoolean}
						errorMessage={errorMessage}
					/>
				) : (
					<StartButton
						setSessionId={setSessionId}
						setStartQuestions={setStartQuestions}
						setCurrentQuestion={setCurrentQuestion}
						createMapQuestionObject={createMapQuestionObject}
						setIsBoolean={setIsBoolean}
						setIsDropDown={setIsDropDown}
					/>
				)}
			</div>
			<p className="c-questions__help">
				If you are not sure how to answer, check the{" "}
				<Link to="/help">Help</Link>
			</p>
		</section>
	);
}

export default QuestionsPage;
