import "./Table.scss";
import React from "react";
import { useMemo } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import GlobalFilter from "./GlobalFilter";
import { CSVLink } from "react-csv";
import DefaultColumnFilter from "./DefaultColumnFilter";
import { fuzzyTextFilterFn } from "./Filters";
import UpArrow from "../../../svgs/up-arrow.svg";
import DownArrow from "../../../svgs/down-arrow.svg";
import Checkbox from "../../../svgs/checkbox.svg";

export default function Table({ columns, data }) {
	// table action (this code taken directly from react-table docs)
	// eslint-disable-next-line react/display-name
	const ToggleAllCheckbox = React.forwardRef(
		({ indeterminate, ...rest }, ref) => {
			const defaultRef = React.useRef();
			const resolvedRef = ref || defaultRef;
			React.useEffect(() => {
				resolvedRef.current.indeterminate = indeterminate;
			}, [resolvedRef, indeterminate]);

			return <input type="checkbox" ref={resolvedRef} {...rest} />;
		}
	);
	//table action
	const csvHeader = [];

	for (let i = 0; i < columns.length; i++) {
		if (columns[i].Header) {
			const header = {
				label: columns[i].Header,
				key: columns[i].id ? columns[i].id : columns[i].accessor,
			};
			csvHeader.push(header);
		}
	}
	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	// Use the useTable Hook to send the columns and data to build the table
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
		/**
		 * Prepare the row (this function needs to be called for each row
		 * before getting the row props)
		 */
		prepareRow,
		allColumns,
		getToggleHideAllColumnsProps,
		// visibleColumns,
		preGlobalFilteredRows,
		setGlobalFilter,
		state,
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: {
				hiddenColumns: ["date2", "numOfTests", "isTestCompleted", "link"],
			},
		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		useSortBy
	);

	/**
	 * Render the UI for your table
	 * - react-table doesn't have UI, it's headless.
	 * We just need to put the react-table props from the Hooks,
	 * and it will do its magic automatically
	 */
	return (
		<>
			{/* TODO this should be hived off into its own TableActions component */}
			<section
				className="c-table-actions"
				aria-label="Actions on the whole table."
			>
				<GlobalFilter
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>
				<div className="c-table-actions__toggles">
					<h3 className="c-table-actions__cue o-type__heading--6">
						Toggle Columns
					</h3>
					<div className="c-form__checkbox">
						<ToggleAllCheckbox {...getToggleHideAllColumnsProps()} />
						<label htmlFor="toggle_all">
							<span className="invisible">Toggle All</span>
							<Checkbox />
						</label>
					</div>
					{allColumns.map((column) => (
						<div className="c-form__checkbox" key={column.id} title={column.id}>
							<input
								id={`toggle_${column.id}`}
								type="checkbox"
								{...column.getToggleHiddenProps()}
							/>
							<label htmlFor={`toggle_${column.id}`}>
								<span className="invisible">{column.id}</span>
								<Checkbox />
							</label>
						</div>
					))}
				</div>

				<CSVLink
					headers={csvHeader}
					data={data}
					filename={"candidate-list.csv"}
					className="c-table-actions__csv o-button o-button--secondary"
				>
					{"Download CSV"}
				</CSVLink>
			</section>

			<div className="c-table__wrapper">
				<table className={"c-table"} {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup, i) => (
							<tr
								className={"c-table__head"}
								key={i}
								{...headerGroup.getHeaderGroupProps()}
							>
								{headerGroup.headers.map((column, i) => (
									<th
										key={i}
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={
											"c-table__cell" +
											(column.isSorted
												? column.isSortedDesc
													? " sort-desc"
													: " sort-asc"
												: "")
										}
									>
										<div className="c-table__cell-content">
											<h4 className="c-table__heading o-type__heading--6">
												{column.render("Header")}
											</h4>
											{/* If column is sortable add a sort direction indicator */}
											{!column.disableSortBy && (
												<>
													{" "}
													<button
														className={`o-button o-button__icon ${
															column.isSorted && !column.isSortedDesc
																? "o-button--active"
																: ""
														}`}
													>
														<span className="invisible">Sort Up</span>
														<UpArrow />
													</button>
													<button
														className={`o-button o-button__icon ${
															column.isSortedDesc ? "o-button--active" : ""
														}`}
													>
														<span className="invisible">Sort Down</span>
														<DownArrow />
													</button>
												</>
											)}
										</div>
										{/* Render the columns filter UI */}
										<div className={"c-filter c-form__input"}>
											{column.canFilter ? column.render("Filter") : null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr className={"c-table__row"} key={i} {...row.getRowProps()}>
									{row.cells.map((cell, i) => {
										return (
											<td
												className="c-table__cell"
												data-property={cell.column.id}
												data-value={cell.value}
												key={i}
												{...cell.getCellProps()}
											>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
